import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import Services from 'services/network';
import { actionTypes } from 'common/constants/invoices';
import {
  CartUnitTypes,
  IAddInvoiceUnitDTO,
  IBalance,
  ICreateInvoiceRequestPayload,
  IFetchReceiptPayload,
  IInvoiceDetailsDto,
  IInvoicePaymentSplit,
  INotifications,
  InvoiceStatus,
  IPaymentMethodCustomResponse,
  IRewards,
  ISearchGiftCard,
  ISearchGiftCardsPayload,
  ISearchPackagesRequestPayload,
  ISearchPackagesResult,
  ISocketRemoveMessage,
  IUpdatePersonInfoOfInvoiceSuccessPayload,
  IVoidInvoiceRequestPayload,
  PaymentAccountType,
  PaymentsType,
} from 'common/interfaces/invoices';
import { ISuccessResponse } from 'common/interfaces/http';
import { IPaginatedEmployees } from 'modules/employees/interfaces';
import { enqueueErrorNotification } from '../notifications/actions';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  ICommerceHubCredentials,
  ICustomerShort,
  IPaymentAccount,
} from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { GeneralThunkAction, ImmutableObject } from '../interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import {
  fetchPersonDocuments,
  fetchProfileInfoView,
  fetchRecentPersonPayments,
  updatePersonMembershipPackageAction,
} from 'common/components/PersonProfile/state/actions';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { ActionResult, DictionaryList } from 'common/constants';
import { printHTML, silentPrintHTML } from 'common/state/printHTML/actions';
import { IMemberAlert } from 'modules/front-desk/interfaces';
import { fetchInvoicesList } from 'common/httpHooks/useFetchInvoicesList';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { encryptCreditCardData } from '../../components/InvoiceOperating/InvoicePaymentWizard/steps/creditCard/commerceHubUtils';

export const fetchOpenInvoicesListAction = createAction<IInvoiceDetailsDto[]>(
  actionTypes.FETCH_INVOICES_LIST,
);
const fetchOpenInvoicesListLoadingAction = createAction<boolean>(
  actionTypes.FETCH_INVOICES_LIST_LOADING,
);
export const resetOpenInvoicesList = createAction(actionTypes.RESET_INVOICES_LIST);
export const resetInvoiceOperatingState = createAction(actionTypes.RESET_INVOICE_OPERATING_STATE);
export const fetchOpenInvoicesList = (
  module: PeakModules,
  registerId: string,
  isMembershipStep?: boolean,
  shouldFocus?: boolean,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchOpenInvoicesListLoadingAction(true));

    const invoicesData = await fetchInvoicesList(module, registerId, isMembershipStep);

    batch(() => {
      if (shouldFocus) {
        dispatch(selectInvoice(invoicesData[0]?.id));
      }
      dispatch(fetchOpenInvoicesListAction(invoicesData));
    });
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchOpenInvoicesListLoadingAction(false));
  }
};

export const createInvoiceAction = createAction<IInvoiceDetailsDto>(actionTypes.CREATE_INVOICE);
const createInvoiceLoadingAction = createAction<boolean>(actionTypes.CREATE_INVOICE_LOADING);

export const createInvoiceRequest = async (
  module: PeakModules,
  registerId: string,
  customerId: string,
  isCustomer: boolean,
) => {
  let createInvoiceResult: IInvoiceDetailsDto;

  switch (module) {
    case PeakModules.FrontDesk:
      createInvoiceResult = await Services.FrontDesk.createNewInvoice(
        registerId,
        customerId,
        isCustomer,
      );
      break;
    case PeakModules.Members:
      createInvoiceResult = await Services.Members.createNewInvoice(registerId, customerId);
      break;
    case PeakModules.PersonalTrainingCrm:
      createInvoiceResult = await Services.PTLeads.createNewInvoice(registerId, customerId);
      break;
    case PeakModules.Crm:
      createInvoiceResult = await Services.Leads.createNewInvoice(registerId, customerId);
      break;
    default:
      createInvoiceResult = await Services.PosKiosk.createNewInvoice(registerId, customerId);
  }

  return createInvoiceResult;
};

export const createInvoice = (
  payload: ICreateInvoiceRequestPayload,
): GeneralThunkAction => async dispatch => {
  try {
    const {
      module,
      isCustomer,
      registerId,
      customerId,
      shouldFocus,
      callback,
      profileId,
    } = payload;

    dispatch(createInvoiceLoadingAction(true));

    // TODO: right now we create an empty invoice on the backend with no data
    // we should add data to invoice in some way during the buying process to store it on the backend
    const createInvoiceResult = await createInvoiceRequest(
      module,
      registerId,
      customerId,
      isCustomer,
    );

    if (callback) {
      callback();
    }

    dispatch(createInvoiceAction(createInvoiceResult));

    if (shouldFocus) {
      dispatch(selectInvoice(createInvoiceResult.id));
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(createInvoiceLoadingAction(false));
  }
};

export const updateSocketInvoicesListAction = createAction<IInvoiceDetailsDto>(
  actionTypes.UPDATE_SOCKET_INVOICES_LIST,
);
export const removeSocketInvoiceAction = createAction<ISocketRemoveMessage>(
  actionTypes.REMOVE_SOCKET_INVOICE,
);

// Add invoice unit

const addInvoiceUnitAction = createAction<IInvoiceDetailsDto>(actionTypes.ADD_INVOICE_UNIT);
const addInvoiceUnitLoadingAction = createAction<boolean>(actionTypes.ADD_INVOICE_UNIT_LOADING);

export const addInvoiceUnit = (
  invoiceId: string,
  data: Partial<IAddInvoiceUnitDTO>,
  unitType: CartUnitTypes,
  module: PeakModules,
  isCustomer: boolean,
  shouldFocus?: boolean,
  callback?: () => void,
): GeneralThunkAction => async dispatch => {
  dispatch(addInvoiceUnitLoadingAction(true));

  try {
    let addInvoiceUnitResult;

    switch (module) {
      case PeakModules.FrontDesk:
        addInvoiceUnitResult = await Services.FrontDesk.addInvoiceUnit(
          invoiceId,
          data,
          unitType,
          isCustomer,
        );
        break;
      case PeakModules.Members:
        addInvoiceUnitResult = await Services.Members.addInvoiceUnit(invoiceId, data, unitType);
        break;
      case PeakModules.Crm:
        addInvoiceUnitResult = await Services.Leads.addInvoiceUnit(invoiceId, data, unitType);
        break;
      case PeakModules.PersonalTrainingCrm:
        addInvoiceUnitResult = await Services.PTLeads.addInvoiceUnit(invoiceId, data, unitType);
        break;
      default:
        addInvoiceUnitResult = await Services.PosKiosk.addInvoiceUnit(invoiceId, data, unitType);
    }

    dispatch(addInvoiceUnitAction(addInvoiceUnitResult));

    if (shouldFocus) {
      dispatch(selectInvoice(addInvoiceUnitResult.id));
    }

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addInvoiceUnitLoadingAction(false));
  }
};

const deleteInvoiceUnitAction = createAction<IInvoiceDetailsDto>(actionTypes.ADD_INVOICE_UNIT);
const deleteInvoiceUnitLoadingAction = createAction<boolean>(actionTypes.ADD_INVOICE_UNIT_LOADING);

export const deleteInvoiceUnit = (
  invoiceId: string,
  invoiceUnitId: string,
  module: PeakModules,
  isCustomer: boolean,
): GeneralThunkAction => async dispatch => {
  dispatch(deleteInvoiceUnitLoadingAction(true));

  try {
    let deleteInvoiceUnitResult;

    switch (module) {
      case PeakModules.FrontDesk:
        deleteInvoiceUnitResult = await Services.FrontDesk.deleteInvoiceUnit(
          invoiceId,
          invoiceUnitId,
          isCustomer,
        );
        break;
      case PeakModules.Members:
        deleteInvoiceUnitResult = await Services.Members.deleteInvoiceUnit(
          invoiceId,
          invoiceUnitId,
        );
        break;
      case PeakModules.Crm:
        deleteInvoiceUnitResult = await Services.Leads.deleteInvoiceUnit(invoiceId, invoiceUnitId);
        break;
      case PeakModules.PersonalTrainingCrm:
        deleteInvoiceUnitResult = await Services.PTLeads.deleteInvoiceUnit(
          invoiceId,
          invoiceUnitId,
        );
        break;
      default:
        deleteInvoiceUnitResult = await Services.PosKiosk.deleteInvoiceUnit(
          invoiceId,
          invoiceUnitId,
        );
    }

    dispatch(deleteInvoiceUnitAction(deleteInvoiceUnitResult));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deleteInvoiceUnitLoadingAction(false));
  }
};

const changeInvoiceUnitNumberAction = createAction<IInvoiceDetailsDto>(
  actionTypes.CHANGE_INVOICE_UNIT_NUMBER,
);
const changeInvoiceUnitNumberLoadingAction = createAction<boolean>(
  actionTypes.CHANGE_INVOICE_UNIT_NUMBER_LOADING,
);

export const changeInvoiceUnitNumber = (
  invoiceId: string,
  invoiceUnitId: string,
  unitNumber: number,
  module: PeakModules,
  isCustomer: boolean,
  shouldFocus?: boolean,
): GeneralThunkAction => async dispatch => {
  dispatch(changeInvoiceUnitNumberLoadingAction(true));

  try {
    let changeInvoiceUnitNumberResult;

    switch (module) {
      case PeakModules.FrontDesk:
        changeInvoiceUnitNumberResult = await Services.FrontDesk.changeInvoiceUnitNumber(
          invoiceId,
          invoiceUnitId,
          unitNumber,
          isCustomer,
        );
        break;
      case PeakModules.Members:
        changeInvoiceUnitNumberResult = await Services.Members.changeInvoiceUnitNumber(
          invoiceId,
          invoiceUnitId,
          unitNumber,
        );
        break;
      case PeakModules.Crm:
        changeInvoiceUnitNumberResult = await Services.Leads.changeInvoiceUnitNumber(
          invoiceId,
          invoiceUnitId,
          unitNumber,
        );
        break;
      case PeakModules.PersonalTrainingCrm:
        changeInvoiceUnitNumberResult = await Services.PTLeads.changeInvoiceUnitNumber(
          invoiceId,
          invoiceUnitId,
          unitNumber,
        );
        break;
      default:
        changeInvoiceUnitNumberResult = await Services.PosKiosk.changeInvoiceUnitNumber(
          invoiceId,
          invoiceUnitId,
          unitNumber,
        );
    }

    dispatch(changeInvoiceUnitNumberAction(changeInvoiceUnitNumberResult));

    if (shouldFocus) {
      dispatch(selectInvoice(changeInvoiceUnitNumberResult.id));
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(changeInvoiceUnitNumberLoadingAction(false));
  }
};

const updateInvoiceLoadingAction = createAction<boolean>(actionTypes.UPDATE_INVOICE_LOADING);

export const selectInvoice = createAction<string | null>(actionTypes.SELECT_CURRENT_INVOICE);

export const removeInvoice = createAction<string>(actionTypes.REMOVE_INVOICE);

export interface IVoidInvoiceSuccessResponse extends ISuccessResponse {
  invoiceId: string;
  shouldGoBack: boolean;
}

export type IVoidInvoiceSuccessResponseImt = ImmutableObject<IVoidInvoiceSuccessResponse>;
const cancelInvoiceAction = createAction<IVoidInvoiceSuccessResponse>(actionTypes.CANCEL_INVOICE);
const cancelInvoiceLoadingAction = createAction<boolean>(actionTypes.CANCEL_INVOICE_LOADING);
export const resetCancelInvoice = createAction(actionTypes.RESET_CANCEL_INVOICE);

export const cancelInvoiceRequest = async (
  module: PeakModules,
  isMembershipStep: boolean,
  invoiceId: string,
) => {
  switch (module) {
    case PeakModules.FrontDesk:
      if (isMembershipStep) await Services.FrontDesk.cancelMembershipInvoice(invoiceId);
      else await Services.FrontDesk.cancelInvoiceFromPOS(invoiceId);
      break;
    case PeakModules.Members:
      await Services.Members.cancelInvoice(invoiceId);
      break;
    case PeakModules.PersonalTrainingCrm:
      await Services.PTLeads.cancelInvoice(invoiceId);
      break;
    case PeakModules.Crm:
      await Services.Leads.cancelInvoice(invoiceId);
      break;
    default:
      await Services.PosKiosk.cancelInvoice(invoiceId);
  }
};

export const cancelInvoice = (
  module: PeakModules,
  invoiceId: string,
  profileId: string,
  isMembershipStep?: boolean,
  shouldGoPrevStep?: boolean,
  isRecentPersonPaymentsUpdate?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelInvoiceLoadingAction(true));

    try {
      await cancelInvoiceRequest(module, isMembershipStep, invoiceId);

      batch(() => {
        dispatch(
          cancelInvoiceAction({
            success: true,
            invoiceId,
            shouldGoBack: shouldGoPrevStep,
          }),
        );

        dispatch(removeInvoice(invoiceId));
      });

      if (isRecentPersonPaymentsUpdate) {
        dispatch(fetchRecentPersonPayments(profileId, module));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelInvoiceLoadingAction(false));
    }
  };
};

export const voidInvoice = (payload: IVoidInvoiceRequestPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelInvoiceLoadingAction(true));

    try {
      const {
        isMembershipStep,
        isRecentPersonPaymentsUpdate,
        module,
        invoiceId,
        shouldGoPrevStep,
        reason,
        profileId,
      } = payload;

      switch (module) {
        case PeakModules.FrontDesk:
          if (isMembershipStep) await Services.FrontDesk.voidMembershipInvoice(invoiceId, reason);
          else await Services.FrontDesk.voidInvoiceFromPOS(invoiceId, reason);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.voidInvoice(invoiceId, reason);
          break;
        case PeakModules.Crm:
          await Services.Leads.voidInvoice(invoiceId, reason);
          break;
        default:
          await Services.PosKiosk.voidInvoice(invoiceId, reason);
      }

      batch(() => {
        dispatch(
          cancelInvoiceAction({
            success: true,
            invoiceId,
            shouldGoBack: shouldGoPrevStep,
          }),
        );
        dispatch(removeInvoice(invoiceId));

        if (isRecentPersonPaymentsUpdate) {
          dispatch(fetchRecentPersonPayments(profileId, module));
        }
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelInvoiceLoadingAction(false));
    }
  };
};

export const sendNotifications = (
  module: PeakModules,
  invoiceId: string,
  isCustomer: boolean,
  data: INotifications,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateInvoiceLoadingAction(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.sendNotifications(invoiceId, data, isCustomer);
          break;
        case PeakModules.Members:
          await Services.Members.sendNotifications(invoiceId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.sendNotifications(invoiceId, data);
          break;
        case PeakModules.Crm:
          await Services.Leads.sendNotifications(invoiceId, data);
          break;
        default:
          await Services.PosKiosk.sendNotifications(invoiceId, data);
      }

      dispatch(resetCheckoutResultData());
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateInvoiceLoadingAction(false));
    }
  };
};

const fetchDataUsersLoading = createAction<boolean>(actionTypes.FETCH_SALESPERSON_LOADING);
const fetchDataEmployeesAction = createAction<IPaginatedEmployees>(actionTypes.FETCH_SALESPERSON);
export const resetDataEmployees = createAction(actionTypes.RESET_SALESPERSON);

export const fetchDataEmployees = (searchStr: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchDataUsersLoading(true));

    try {
      const dataEmployees = await Services.Employees.getEmployeesListWithSearch(searchStr);
      dispatch(fetchDataEmployeesAction(dataEmployees));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchDataUsersLoading(false));
    }
  };
};

const fetchDataMembersAction = createAction<ICustomerShort[]>(actionTypes.FETCH_CUSTOMERS_LIST);
export const resetDataMembers = createAction(actionTypes.RESET_CUSTOMERS_LIST);

export const fetchDataMembers = (searchStr: string, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchDataUsersLoading(true));

    try {
      let dataMembers: ICustomerShort[];

      if (module === PeakModules.FrontDesk) {
        dataMembers = await Services.FrontDesk.searchFornkDeskPOSMembers(searchStr);
      } else {
        dataMembers = await Services.PosKiosk.searchPOSMembers(searchStr);
      }

      dispatch(fetchDataMembersAction(dataMembers));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchDataUsersLoading(false));
    }
  };
};

// search
const fetchSearchInventoryLoading = createAction<boolean>(
  actionTypes.FETCH_SEARCH_INVENTORY_LOADING,
);
// TODO CREATE INTERFACE
const fetchSearchInventoryAction = createAction<IPaginatedData<ISimpleClubInventoryItem>>(
  actionTypes.FETCH_SEARCH_INVENTORY,
);
export const resetSearchInventoryResult = createAction(actionTypes.RESET_SEARCH_INVENTORY_RESULT);

export const searchRegisterInventory = (
  module: PeakModules,
  registerId: string,
  isCustomer: boolean,
  search: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSearchInventoryLoading(true));

    try {
      let searchResult: IPaginatedData<ISimpleClubInventoryItem>;

      switch (module) {
        case PeakModules.FrontDesk:
          searchResult = await Services.FrontDesk.searchPOSInventory(
            registerId,
            search,
            isCustomer,
          );
          break;
        case PeakModules.Members:
          searchResult = await Services.Members.searchPOSInventory(registerId, search);
          break;
        case PeakModules.PersonalTrainingCrm:
          searchResult = await Services.PTLeads.searchPOSInventory(registerId, search);
          break;
        case PeakModules.Crm:
          searchResult = await Services.Leads.searchPOSInventory(registerId, search);
          break;
        default:
          searchResult = await Services.PosKiosk.searchPOSInventory(registerId, search);
      }
      dispatch(fetchSearchInventoryAction(searchResult));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSearchInventoryLoading(false));
    }
  };
};

const fetchSearchPackagesLoading = createAction<boolean>(actionTypes.FETCH_SEARCH_PACKAGES_LOADING);
const fetchSearchPackagesAction = createAction<ISearchPackagesResult>(
  actionTypes.FETCH_SEARCH_PACKAGES,
);
export const resetSearchPackagesResult = createAction(actionTypes.RESET_SEARCH_PACKAGES_RESULT);

export const searchClubServicePackages = ({
  module,
  employeeClubId: clubId,
  isPaymentStep: isCustomer,
  searchStr: search,
  salesPersonId,
}: ISearchPackagesRequestPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSearchPackagesLoading(true));

    try {
      let searchResult: ISearchPackagesResult;

      switch (module) {
        case PeakModules.FrontDesk:
          searchResult = await Services.FrontDesk.searchPOSPackages(
            search,
            clubId,
            salesPersonId,
            isCustomer,
          );
          break;
        case PeakModules.Members:
          searchResult = await Services.Members.searchPOSPackages(search, clubId, salesPersonId);
          break;
        case PeakModules.PersonalTrainingCrm:
          searchResult = await Services.PTLeads.searchPOSPackages(search, clubId, salesPersonId);
          break;
        case PeakModules.Crm:
          searchResult = await Services.Leads.searchPOSPackages(search, clubId, salesPersonId);
          break;
        default:
          searchResult = await Services.PosKiosk.searchPOSPackages(search, clubId, salesPersonId);
      }
      dispatch(fetchSearchPackagesAction(searchResult));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSearchPackagesLoading(false));
    }
  };
};

const fetchSearchGiftCardsAction = createAction<ISearchGiftCard[]>(
  actionTypes.FETCH_SEARCH_GIFT_CARDS,
);
const fetchSearchGiftCardsLoading = createAction<boolean>(
  actionTypes.FETCH_SEARCH_GIFT_CARDS_LOADING,
);
export const resetSearchGiftCards = createAction(actionTypes.RESET_SEARCH_GIFT_CARDS);

export const searchClubGiftCards = ({
  module,
  searchStr: search,
  clubIds,
  isCustomer,
}: ISearchGiftCardsPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSearchGiftCardsLoading(true));

    try {
      let searchResult: ISearchGiftCard[];

      switch (module) {
        case PeakModules.FrontDesk:
          searchResult = await Services.FrontDesk.searchPOSGiftCards(clubIds, search, isCustomer);
          break;
        case PeakModules.Members:
          searchResult = await Services.Members.searchPOSGiftCards(clubIds, search);
          break;
        case PeakModules.PersonalTrainingCrm:
          searchResult = await Services.PTLeads.searchPOSGiftCards(clubIds, search);
          break;
        case PeakModules.Crm:
          searchResult = await Services.Leads.searchPOSGiftCards(clubIds, search);
          break;
        default:
          searchResult = await Services.PosKiosk.searchPOSGiftCards(clubIds, search);
      }

      dispatch(fetchSearchGiftCardsAction(searchResult));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSearchGiftCardsLoading(false));
    }
  };
};

// Payments

const fetchPMBarcodeLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING,
);
const fetchPMBarcodeResultAction = createAction<IPaymentMethodCustomResponse>(
  actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT,
);
const fetchPMBarcodeErrorAction = createAction<any>(
  actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR,
);
export const resetFetchPMBarcode = createAction(actionTypes.RESET_FETCH_PAYMENT_METHOD_BY_BARCODE);
export const fetchPaymentMethodByBarcode = (
  registerId: string,
  barcode: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPMBarcodeLoadingAction(true));

    try {
      const result = await Services.PosSettings.getPaymentMethodCustomByBarcode(
        barcode,
        registerId,
      );
      dispatch(fetchPMBarcodeResultAction(result));
    } catch (error) {
      dispatch(fetchPMBarcodeErrorAction(error));
    } finally {
      dispatch(fetchPMBarcodeLoadingAction(false));
    }
  };
};

const fetchAvailablePaymentMethodsAction = createAction<IPaymentMethodItem[]>(
  actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_RESULT,
);
const fetchAvailablePaymentMethodsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_LOADING,
);
export const resetAvailablePaymentMethodsList = createAction(
  actionTypes.RESET_AVAILABLE_PAYMENT_METHODS,
);
export const fetchAvailablePaymentMethods = (
  module: PeakModules,
  registerId: string,
  isCustomer?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAvailablePaymentMethodsLoadingAction(true));

    try {
      let result;

      switch (module) {
        case PeakModules.FrontDesk:
          result = await (isCustomer
            ? Services.FrontDesk.fetchCustomerAvailablePMethods(registerId)
            : Services.FrontDesk.fetchAvailablePaymentMethods(registerId));
          break;
        case PeakModules.Crm:
          result = await Services.Leads.fetchAvailablePaymentMethods(registerId);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.fetchAvailablePaymentMethods(registerId);
          break;
        case PeakModules.PosKiosk:
        default:
          result = await Services.PosKiosk.fetchAvailablePaymentMethods(registerId);
      }

      dispatch(fetchAvailablePaymentMethodsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAvailablePaymentMethodsLoadingAction(false));
    }
  };
};

const fetchReceiptLoadingAction = createAction<boolean>(actionTypes.FETCH_RECEIPT_LOADING);
export const fetchReceipt = (payload: IFetchReceiptPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchReceiptLoadingAction(true));

    const { module, invoiceId, isCustomer, isSilentPrint = false } = payload;

    try {
      let result: string;

      switch (module) {
        case PeakModules.FrontDesk:
          result = await (isCustomer
            ? Services.FrontDesk.getCustomerPOSInvoiceReceipt(invoiceId)
            : Services.FrontDesk.getPOSInvoiceReceipt(invoiceId));
          break;
        case PeakModules.Crm:
          result = await Services.Leads.getInvoiceReceipt(invoiceId);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.getInvoiceReceipt(invoiceId);
          break;
        case PeakModules.Members:
          result = await Services.Members.getInvoiceReceipt(invoiceId);
          break;
        case PeakModules.PosKiosk:
        default:
          result = await Services.PosKiosk.getInvoiceReceipt(invoiceId);
      }

      if (isSilentPrint) {
        dispatch(silentPrintHTML(result));
      } else {
        dispatch(printHTML(result));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchReceiptLoadingAction(false));
    }
  };
};

// checkout result receipt data

const setCheckoutResultData = createAction<IInvoiceDetailsDto>(actionTypes.SET_CHECKOUT_RESULT);
export const resetCheckoutResultData = createAction(actionTypes.RESET_CHECKOUT_RESULT);

// invoice sync

const updateInvoiceWithSyncLoadingAction = createAction<boolean>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_LOADING,
);
const updateInvoiceWithSyncResultAction = createAction<IInvoiceDetailsDto>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_RESULT,
);
export const updateInvoiceWithSyncErrorAction = createAction<any>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_ERROR,
);
export const resetUpdateInvoiceWithSync = createAction(actionTypes.RESET_UPDATE_INVOICE_WITH_SYNC);

export const updateInvoiceWithSync = (
  module: PeakModules,
  clubId: string,
  invoiceData: IInvoiceDetailsDto,
  invoicePaymentSplit: IInvoicePaymentSplit,
  profileId?: string,
  isCustomer?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateInvoiceWithSyncLoadingAction(true));

    try {
      let result: IInvoiceDetailsDto;

      const invoiceId = invoiceData.id;
      switch (module) {
        case PeakModules.FrontDesk:
          result = await (isCustomer
            ? Services.FrontDesk.addCustomerInvoicePaymentSplit(invoiceId, invoicePaymentSplit)
            : Services.FrontDesk.addInvoicePaymentSplit(invoiceId, invoicePaymentSplit));
          break;
        case PeakModules.Crm:
          result = await Services.Leads.addInvoicePaymentSplit(invoiceId, invoicePaymentSplit);
          break;
        case PeakModules.PersonalTrainingCrm:
          result = await Services.PTLeads.addInvoicePaymentSplit(invoiceId, invoicePaymentSplit);
          break;
        case PeakModules.PosKiosk:
        default:
          result = await Services.PosKiosk.addInvoicePaymentSplit(invoiceId, invoicePaymentSplit);
      }

      if (result.status === InvoiceStatus.PAID && !isCustomer) {
        dispatch(
          fetchDictionaryList(DictionaryList.REGISTERS, {
            clubIds: clubId ? `${clubId}` : '',
            module,
            isCustomer: false,
            quietLoading: true,
          }),
        );
      }
      const isUpdatedStatusToInProgress =
        invoiceData.status === InvoiceStatus.OPEN && result.status === InvoiceStatus.IN_PROGRESS;
      const isUpdatedStatusToPaid =
        result.status === InvoiceStatus.PAID &&
        (invoiceData.status === InvoiceStatus.IN_PROGRESS ||
          invoiceData.status === InvoiceStatus.OPEN);

      if (
        (isUpdatedStatusToInProgress || isUpdatedStatusToPaid) &&
        module === PeakModules.FrontDesk &&
        profileId &&
        result.customer?.id === profileId
      ) {
        dispatch(fetchRecentPersonPayments(profileId, module));
      }

      if (result.status === InvoiceStatus.PAID) {
        batch(() => {
          dispatch(updateInvoiceWithSyncResultAction(result));
          dispatch(setCheckoutResultData(result));
        });

        if (profileId && module === PeakModules.FrontDesk) {
          dispatch(fetchProfileInfoView(profileId, module));
          dispatch(fetchPersonDocuments(profileId, module));
        }
      } else {
        dispatch(updateInvoiceWithSyncResultAction(result));
      }

      // delete member alert
      if (result.status === InvoiceStatus.PAID && module === PeakModules.FrontDesk && isCustomer) {
        const { id } = invoiceData.customer;

        const alerts = await Services.FrontDeskAlerts.getMemberAlerts(id);

        const alert: IMemberAlert = alerts.find(
          ({ condition }) =>
            condition === AlertCondition.InactiveMember ||
            condition === AlertCondition.MemberWillExpireSoon ||
            condition === AlertCondition.MemberIsFrozen,
        );

        if (alert) {
          dispatch(updatePersonMembershipPackageAction(alert.condition, id));
        }
      }
    } catch (error) {
      dispatch(updateInvoiceWithSyncErrorAction(error));
    } finally {
      dispatch(updateInvoiceWithSyncLoadingAction(false));
    }
  };
};

export const proceedFreeInvoice = (
  invoiceId: string,
  module: PeakModules,
  clubId: string,
  profileId?: string,
  isCustomer?: boolean,
): GeneralThunkAction => async dispatch => {
  dispatch(updateInvoiceWithSyncLoadingAction(true));

  try {
    let result;

    switch (module) {
      case PeakModules.FrontDesk:
        result = await Services.FrontDesk.proceedFreeInvoice(invoiceId, isCustomer);

        break;
      case PeakModules.Crm:
        result = await Services.Leads.proceedFreeInvoice(invoiceId);
        break;
      case PeakModules.PersonalTrainingCrm:
        result = await Services.PTLeads.proceedFreeInvoice(invoiceId);
        break;
      case PeakModules.PosKiosk:
      default:
        result = await Services.PosKiosk.proceedFreeInvoice(invoiceId);
    }

    batch(() => {
      dispatch(updateInvoiceWithSyncResultAction(result));
      dispatch(setCheckoutResultData(result));
    });

    if (profileId && module === PeakModules.FrontDesk) {
      dispatch(fetchProfileInfoView(profileId, module));
      dispatch(fetchPersonDocuments(profileId, module));
    }

    if (!isCustomer) {
      dispatch(
        fetchDictionaryList(DictionaryList.REGISTERS, {
          clubIds: clubId ? `${clubId}` : '',
          module,
          isCustomer: false,
          quietLoading: true,
        }),
      );
    }

    if (module === PeakModules.FrontDesk && profileId && result.customer?.id === profileId) {
      dispatch(fetchRecentPersonPayments(profileId, module));
    }

    // delete member alert
    if (module === PeakModules.FrontDesk && isCustomer) {
      const { id } = result.customer;

      const alerts = await Services.FrontDeskAlerts.getMemberAlerts(id);

      const alert: IMemberAlert = alerts.find(
        ({ condition }) =>
          condition === AlertCondition.InactiveMember ||
          condition === AlertCondition.MemberWillExpireSoon ||
          condition === AlertCondition.MemberIsFrozen,
      );

      if (alert) {
        dispatch(updatePersonMembershipPackageAction(alert.condition, id));
      }
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(updateInvoiceWithSyncLoadingAction(false));
  }
};

const fetchStoredCreditCardsLoadingAction = createAction<any>(
  actionTypes.FETCH_STORED_CREDIT_CARDS_LOADING,
);
const fetchStoredCreditCardsResultAction = createAction<boolean>(
  actionTypes.FETCH_STORED_CREDIT_CARDS_RESULT,
);
export const fetchStoredCreditCards = (
  module: PeakModules,
  cardType: PaymentsType,
  memberId: string,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchStoredCreditCardsLoadingAction(true));

    let fetchCreditCardsResult;

    switch (module) {
      case PeakModules.FrontDesk:
        fetchCreditCardsResult = await Services.FrontDesk.fetchPaymentAccounts(
          memberId,
          PaymentAccountType.CREDIT_CARD,
          cardType,
        );
        break;
      case PeakModules.Members:
        fetchCreditCardsResult = await Services.Members.fetchPaymentAccounts(
          memberId,
          PaymentAccountType.CREDIT_CARD,
          cardType,
        );
        break;
      case PeakModules.PersonalTrainingCrm:
        fetchCreditCardsResult = await Services.PTLeads.fetchPaymentAccounts(
          memberId,
          PaymentAccountType.CREDIT_CARD,
          cardType,
        );
        break;
      case PeakModules.Crm:
        fetchCreditCardsResult = await Services.Leads.fetchPaymentAccounts(
          memberId,
          PaymentAccountType.CREDIT_CARD,
          cardType,
        );
        break;
      default:
        fetchCreditCardsResult = await Services.PosKiosk.fetchPaymentAccounts(
          memberId,
          PaymentAccountType.CREDIT_CARD,
          cardType,
        );
    }

    dispatch(fetchStoredCreditCardsResultAction(fetchCreditCardsResult));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchStoredCreditCardsLoadingAction(false));
  }
};

export const resetStoredCreditCardsReducer = createAction(
  actionTypes.RESET_STORED_CREDIT_CARDS_REDUCER,
);
const storeCreditCardLoadingAction = createAction<boolean>(actionTypes.STORE_CREDIT_CARD_LOADING);
const storeCreditCardResultAction = createAction<ActionResult>(
  actionTypes.STORE_CREDIT_CARD_RESULT,
);
export const resetStoredCreditCardResult = createAction(
  actionTypes.RESET_STORED_CREDIT_CARD_RESULT,
);
export const storeCreditCard = (
  memberId: string,
  data: IAddCreditCard,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true));
    dispatch(storeCreditCardResultAction(null));
    await Services.FrontDesk.addCreditCardPaymentAccount(memberId, data);
    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false));
  }
};

const setSalespersonToInvoiceSuccess = createAction<IUpdatePersonInfoOfInvoiceSuccessPayload>(
  actionTypes.SET_SALESPERSON_TO_INVOICE_SUCCESS,
);

export const setSalespersonToInvoice = (
  invoiceId: string,
  salespersonId: string,
  isPaymentStep: boolean,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(updateInvoiceLoadingAction(true));

    let response: IInvoiceDetailsDto;

    switch (module) {
      case PeakModules.PosKiosk:
        response = await Services.PosKiosk.setSalespersonToInvoice(invoiceId, salespersonId);
        break;
      case PeakModules.FrontDesk:
        response = await (isPaymentStep
          ? Services.FrontDesk.setSalespersonToInvoiceCustomerPos(invoiceId, salespersonId)
          : Services.FrontDesk.setSalespersonToInvoice(invoiceId, salespersonId));
        break;
      case PeakModules.Members:
        response = await Services.Members.setSalespersonToInvoice(invoiceId, salespersonId);
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.setSalespersonToInvoice(invoiceId, salespersonId);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.setSalespersonToInvoice(invoiceId, salespersonId);
        break;
      default:
    }

    const { id, salesperson: person } = response;

    dispatch(setSalespersonToInvoiceSuccess({ invoiceId: id, person }));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(updateInvoiceLoadingAction(false));
  }
};

const setCustomerToInvoiceSuccess = createAction<IUpdatePersonInfoOfInvoiceSuccessPayload>(
  actionTypes.SET_CUSTOMER_TO_INVOICE_SUCCESS,
);

export const setCustomerToInvoice = (
  invoiceId: string,
  customerId: string | null,
  isPaymentStep: boolean,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(updateInvoiceLoadingAction(true));

    let response: IInvoiceDetailsDto;

    switch (module) {
      case PeakModules.PosKiosk:
        response = await Services.PosKiosk.setCustomerToInvoice(invoiceId, customerId);
        break;
      case PeakModules.FrontDesk:
        response = await (isPaymentStep
          ? Services.FrontDesk.setCustomerToInvoiceCustomerPos(invoiceId, customerId)
          : Services.FrontDesk.setCustomerToInvoicePos(invoiceId, customerId));
        break;
      case PeakModules.Members:
        response = await Services.Members.setCustomerToInvoice(invoiceId, customerId);
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.setCustomerToInvoice(invoiceId, customerId);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.setCustomerToInvoice(invoiceId, customerId);
        break;
      default:
    }

    const { id, customer: person } = response;

    dispatch(setCustomerToInvoiceSuccess({ invoiceId: id, person }));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(updateInvoiceLoadingAction(false));
  }
};

export const resetInvoiceData = (): GeneralThunkAction => async dispatch => {
  dispatch(resetInvoiceOperatingState());
};

// Checking
const fetchInvoiceCheckingSavingsData = createAction<IPaymentAccount[]>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA,
);
const fetchInvoiceCheckingSavingsDataLoading = createAction<boolean>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING,
);
export const resetInvoiceCheckingSavingsData = createAction(
  actionTypes.RESET_CHECKING_SAVINGS_DATA,
);

export const fetchInvoiceCheckingSavingsDataThunk = (
  personId: string,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchInvoiceCheckingSavingsDataLoading(true));

    let response: IPaymentAccount[];

    switch (module) {
      case PeakModules.FrontDesk:
        response = await Services.FrontDesk.fetchPaymentAccounts(
          personId,
          PaymentAccountType.ECHECK,
        );
        break;
      case PeakModules.Members:
        response = await Services.Members.fetchPaymentAccounts(personId, PaymentAccountType.ECHECK);
        break;
      case PeakModules.PosKiosk:
        response = await Services.PosKiosk.fetchPaymentAccounts(
          personId,
          PaymentAccountType.ECHECK,
        );
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.fetchPaymentAccounts(personId, PaymentAccountType.ECHECK);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.fetchPaymentAccounts(personId, PaymentAccountType.ECHECK);
        break;
      default:
        response = [];
    }

    dispatch(fetchInvoiceCheckingSavingsData(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchInvoiceCheckingSavingsDataLoading(false));
  }
};

const addInvoiceCheckingSavingsData = createAction<IPaymentAccount>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA,
);
const addInvoiceCheckingSavingsDataLoading = createAction<boolean>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING,
);
export const addInvoiceCheckingSavingsDataActionResult = createAction<ActionResult | null>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT,
);

export const addInvoiceCheckingSavingsDataThunk = (
  personId: string,
  formData: Omit<IPaymentAccount, 'id'>,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(addInvoiceCheckingSavingsDataLoading(true));

    let response: IPaymentAccount;

    switch (module) {
      case PeakModules.FrontDesk:
        response = await Services.FrontDesk.addCheckingPaymentAccount(personId, formData);
        break;
      case PeakModules.Members:
        response = await Services.Members.addCheckingPaymentAccount(personId, formData);
        break;
      case PeakModules.PosKiosk:
        response = await Services.PosKiosk.addCheckingPaymentAccount(personId, formData);
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.addCheckingPaymentAccount(personId, formData);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.addCheckingPaymentAccount(personId, formData);
        break;
      default:
        response = null;
    }

    if (response) {
      dispatch(addInvoiceCheckingSavingsData(response));
      dispatch(addInvoiceCheckingSavingsDataActionResult(ActionResult.SUCCESS_ACTION));
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addInvoiceCheckingSavingsDataLoading(false));
  }
};

const deleteInvoiceCheckingSavingsItem = createAction<string>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM,
);
const deleteInvoiceCheckingSavingsItemLoading = createAction<boolean>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING,
);
export const deleteInvoiceCheckingSavingsItemActionResult = createAction<ActionResult | null>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT,
);

const fetchCustomerBalanceLoading = createAction<boolean>(
  actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID_LOADING,
);
const fetchCustomerBalance = createAction<IBalance>(actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID);
export const resetCustomerBalance = createAction(actionTypes.RESET_BALANCE);

export const fetchCustomerBalanceThunk = (
  customerId: string,
  module?: PeakModules,
  isPaymentStep = false,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchCustomerBalanceLoading(true));

    let response: IBalance;
    switch (module) {
      case PeakModules.FrontDesk:
        if (isPaymentStep) {
          response = await Services.FrontDesk.fetchBalanceByIdCustomerPOS(customerId);
        } else {
          response = await Services.FrontDesk.fetchBalanceByIdPOS(customerId);
        }
        break;
      case PeakModules.Members:
        response = await Services.Members.fetchBalanceById(customerId);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.fetchBalanceById(customerId);
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.fetchBalanceById(customerId);
        break;
      default:
        response = await Services.PosKiosk.fetchBalanceById(customerId);
    }
    dispatch(fetchCustomerBalance(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchCustomerBalanceLoading(false));
  }
};

const fetchCustomerRewardsLoading = createAction<boolean>(
  actionTypes.FETCH_REWARDS_BY_CUSTOMER_ID_LOADING,
);
const fetchCustomerRewards = createAction<IRewards>(actionTypes.FETCH_REWARDS_BY_CUSTOMER_ID);
export const resetCustomerRewards = createAction(actionTypes.RESET_REWARDS);

export const fetchCustomerRewardsThunk = (
  customerId: string,
  module?: PeakModules,
  isPaymentStep = false,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchCustomerRewardsLoading(true));

    let response: IRewards;
    switch (module) {
      case PeakModules.FrontDesk:
        if (isPaymentStep) {
          response = await Services.FrontDesk.fetchRewardsByIdCustomerPOS(customerId);
        } else {
          response = await Services.FrontDesk.fetchRewardsByIdPOS(customerId);
        }
        break;
      case PeakModules.Members:
        response = await Services.Members.fetchRewardsById(customerId);
        break;
      case PeakModules.Crm:
        response = await Services.Leads.fetchRewardsById(customerId);
        break;
      case PeakModules.PersonalTrainingCrm:
        response = await Services.PTLeads.fetchRewardsById(customerId);
        break;
      default:
        response = await Services.PosKiosk.fetchRewardsById(customerId);
    }
    dispatch(fetchCustomerRewards(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchCustomerRewardsLoading(false));
  }
};

const fetchCommerceHubCredentialsAction = createAction<ICommerceHubCredentials>(
  actionTypes.FETCH_COMMERCEHUB_CREDENTIALS,
);
const fetchCommerceHubCredentialsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_COMMERCEHUB_CREDENTIALS_LOADING,
);
export const fetchCommerceHubCredentials = (
  memberId: string,
  module?: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchCommerceHubCredentialsLoadingAction(true));

    let credentials: ICommerceHubCredentials;
    switch (module) {
      case PeakModules.FrontDesk:
        credentials = await Services.FrontDesk.fetchCommerceHubCredentials(memberId);
        break;
      case PeakModules.Members:
        credentials = await Services.Members.fetchCommerceHubCredentials(memberId);
        break;
      case PeakModules.PersonalTrainingCrm:
        credentials = await Services.PTLeads.fetchCommerceHubCredentials(memberId);
        break;
      case PeakModules.Crm:
        credentials = await Services.Leads.fetchCommerceHubCredentials(memberId);
        break;
      default:
        credentials = await Services.PosKiosk.fetchCommerceHubCredentials(memberId);
        break;
    }

    dispatch(fetchCommerceHubCredentialsAction(credentials));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchCommerceHubCredentialsLoadingAction(false));
  }
};

export const tokenizeCommerceHubEncryptedCard = (
  memberId: string,
  sessionId: string,
  module?: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true));
    dispatch(storeCreditCardResultAction(null));

    const body = { sessionId };
    switch (module) {
      case PeakModules.FrontDesk:
        await Services.FrontDesk.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
      case PeakModules.Members:
        await Services.Members.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
      case PeakModules.PersonalTrainingCrm:
        await Services.PTLeads.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
      case PeakModules.Crm:
        await Services.Leads.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
      default:
        await Services.PosKiosk.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
    }

    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false));
  }
};
